<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> လမ်းညွှန် ထည့်ရန်</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputType" class="col-sm-2 col-form-label">လမ်းညွှန် အမျိုးအစား <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<select v-model="directoryRequest.type" @blur="validationRule()"
										class="form-control" id="inputType">
										<option value="">ရွေးချယ်မယ်</option>
										<option value="education">ပညာရေးဆိုင်ရာ</option>
										<option value="finance">ငွေကြေးဆိုင်ရာ</option>
										<option value="hotel">တည်းခိုမှုဆိုင်ရာ</option>
									</select>
									<small class="text-danger">{{validationData.type}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputTitle" class="col-sm-2 col-form-label">လမ်းညွှန် ခေါင်းစီး <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.title" @blur="validationRule()"
										class="form-control" id="inputTitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputTitleEn" class="col-sm-2 col-form-label">လမ်းညွှန် ခေါင်းစီး (EN)<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.title_en" @blur="validationRule()"
										class="form-control" id="inputTitleEn">
									<small class="text-danger">{{validationData.title_en}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPhone" class="col-sm-2 col-form-label">ဖုန်းနံပါတ်<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.phone" @blur="validationRule()"
										class="form-control" id="inputPhone">
									<small class="text-danger">{{validationData.phone}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputAddress" class="col-sm-2 col-form-label">လိပ်စာ <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="directoryRequest.address" @blur="validationRule()"
										class="form-control" id="inputAddress">
									<small class="text-danger">{{validationData.address}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">ဓာတ်ပုံ  <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="directoryRequest.image" v-bind:src="directoryRequest.image" class="banner-image mt-2"/>
									<small class="text-danger">{{validationData.image}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'directoryCreate',
		metaInfo: {
            title: "directory Create",
            titleTemplate: "%s ← SC Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentImage: "",
				directoryRequest: {
					type: "",
					title: "",
					title_en: "",
					phone: "",
					address: "",
					image: ""
				},
				validationData: {
					type: "",
					title: "",
					title_en: "",
					phone: "",
					address: "",
					image: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				directoryStoreAction: 'directoryStoreAction',
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.directoryRequest.image = e.target.result
					this.validationRule()
				};
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.directoryRequest
				await this.directoryStoreAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'directory-list', query: {page: 1, reverse: 'desc', sort: 'created_at', title: ''} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.directoryRequest.type == "") {
					this.validationData.type = "The type field is required."
					isValidation.push(true)
				} else {
					this.validationData.type = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.title_en == "") {
					this.validationData.title_en = "The title (EN) field is required."
					isValidation.push(true)
				} else {
					this.validationData.title_en = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.phone == "") {
					this.validationData.phone = "The phone field is required."
					isValidation.push(true)
				} else {
					this.validationData.phone = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.address == "") {
					this.validationData.address = "The address field is required."
					isValidation.push(true)
				} else {
					this.validationData.address = ""
					isValidation.push(false)
				}
				if (this.directoryRequest.image == "") {
					this.validationData.image = "The image field is required."
					isValidation.push(true)
				} else {
					console.log(this.directoryRequest.image)
					this.validationData.image = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>